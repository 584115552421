import axios from 'axios';
import { baseApiUrl } from '@/global';

export async function carregarDadosDaTabela(codigoEmpresa) {
  this.tabela.carregando = true;
  this.tabela.items = [];

  let url = `${baseApiUrl}/objetos-alerta` +
    `?alerta_objetos=report${this.tabela.report}` +
    `&page=${this.tabela.paginaAtual}` +
    `&coluna=${this.tabela.coluna}&direcao=${this.tabela.ordenacao}` +
    `&cod_empresa=${codigoEmpresa}` +
    `&por_pagina=${this.tabela.qtdPorPagina}`;

    if(this.$route.params.estado != null){
      if(this.$route.params.estado != '')
        url += `&uf=${this.$route.params.estado}`;
    }

  let datas = true;

  if (this.filtroCodigoTransportadora != '' && this.filtroCodigoTransportadora != null) {
    url += `&cod_transportadora=${this.filtroCodigoTransportadora}`;
  }

  if (this.filtros.codigo != '' && this.filtros.codigo != null) {
    url += `&codigo=${this.filtros.codigo}`;
    datas = false;
  }

  if (this.filtros.transportadoras != '' && this.filtros.transportadoras != null) {
    url += `&cod_transportadora=${this.filtros.transportadoras}`;
  }

  if (this.filtros.rastreio != '' && this.filtros.rastreio != null) {
    url += `&numero_rastreio=${this.filtros.rastreio}`;
    datas = false;
  }

  if (this.filtros.cepOrigem != '' && this.filtros.cepOrigem != null) {
    url += `&remetente_cep=${this.filtros.cepOrigem}`;
  }

  if (this.filtros.cepDestino != '' && this.filtros.cepDestino != null) {
    url += `&destinatario_cep=${this.filtros.cepDestino}`;
  }  

  if (
    this.filtrosDatas.considerada.inicial != '' && this.filtrosDatas.considerada.inicial != null &&
    this.filtrosDatas.considerada.final != '' && this.filtrosDatas.considerada.final != null
  ) {
    url += `&data_considerada_inicio=${this.filtrosDatas.considerada.inicial}&data_considerada_fim=${this.filtrosDatas.considerada.final}`;
  }

  if (
    this.filtrosDatas.prevista.inicial != '' && this.filtrosDatas.prevista.inicial != null &&
    this.filtrosDatas.prevista.final != '' && this.filtrosDatas.prevista.final != null
  ) {
    url += `&data_prevista_inicio=${this.filtrosDatas.prevista.inicial}&data_prevista_fim=${this.filtrosDatas.prevista.final}`;
  }

  if (
    this.filtrosDatas.entrega.inicial != '' && this.filtrosDatas.entrega.inicial != null &&
    this.filtrosDatas.entrega.final != '' && this.filtrosDatas.entrega.final != null
  ) {
    url += `&data_entrega_inicio=${this.filtrosDatas.entrega.inicial}&data_entrega_fim=${this.filtrosDatas.entrega.final}`;
  }

  if (
    this.filtrosDatas.ultimaPosicao.inicial != '' && this.filtrosDatas.ultimaPosicao.inicial != null &&
    this.filtrosDatas.ultimaPosicao.final != '' && this.filtrosDatas.ultimaPosicao.final != null
  ) {
    url += `&data_ultima_posicao_inicio=${this.filtrosDatas.ultimaPosicao.inicial}&data_ultima_posicao_fim=${this.filtrosDatas.ultimaPosicao.final}`;
  }

  if (
    this.filtrosDatas.postagem.inicial != '' && this.filtrosDatas.postagem.inicial != null &&
    this.filtrosDatas.postagem.final != '' && this.filtrosDatas.postagem.final != null
  ) {
    url += `&data_postagem_inicio=${this.filtrosDatas.postagem.inicial}&data_postagem_fim=${this.filtrosDatas.postagem.final}`;
  }

  if (datas) url += `&fim=${this.camposDatas.dataFinal}&inicio=${this.camposDatas.dataInicial}`;

  await axios.get(url).then(async (res) => {
    this.tabela.items = res.data.dados.data;
    this.tabela.totalRegistros = res.data.dados.total;
    this.tabela.qtdPorPagina = Number(res.data.dados.per_page);
    if(Number(res.data.dados.last_page) == 1 && this.tabela.paginaAtual > 1){
      this.mudarPaginaTabela(1);
    }
  }).finally(() => (this.tabela.carregando = false));
};