class Opcao {
  /**
   * @param {string} titulo
   * @param {string} nome
   * @param {number} valor
   * @param {number} total
   * @param {boolean} carregando
   * @param {string} imagem
   */
  constructor(titulo, nome, valor, total, carregando, imagem) {
    this.titulo = titulo;
    this.nome = nome;
    this.valor = valor;
    this.total = total;
    this.carregando = carregando;
    this.imagem = imagem;
  }

  /** @type {string} */
  titulo;
  /** @type {string} */
  nome;
  /** @type {number} Valor da opção para requisição na API. */
  valor;
  /** @type {number} */
  total;
  /** @type {boolean} */
  carregando;
  /** @type {string} */
  imagem
}

/** @type {Opcao[]} */
export const opcoes = [
  new Opcao("Tentativa entrega", 'tentativa_entrega', 1, 0, true, '/icones/tentativa_entrega.svg'),
  new Opcao("Devolvido", 'devolvido', 2, 0, true, '/icones/devolucao.svg'),
  new Opcao("Extravio", 'extravio', 3, 0, true, '/icones/extravio.svg'),
  new Opcao("Roubo", 'roubo', 4, 0, true, '/icones/roubo.svg'),
  new Opcao("Falha remetente", 'falha_remetente', 5, 0, true, '/icones/falha_remetente.svg'),
  new Opcao("Falha fiscal", 'falha_fiscal', 6, 0, true, '/icones/falha_fiscal.svg'),
  new Opcao("Aguardando retirada", 'aguardando_retirada', 7, 0, true, '/icones/retirada.svg'),
  new Opcao("Avaria", 'avaria', 8, 0, true, '/icones/avaria.svg'),
  new Opcao("Alertas Gerais", 'alerta', 9, 0, true, '/icones/alerta.svg'),
  // new Opcao("Movimento pós-entrega", 'pos_entrega', 10, 0, true, '/icones/alerta.svg'),
];