import { baseApiUrl } from '@/global';
import axios from 'axios';

export async function obterQuantidades(report, codigoEmpresa) {
  let total;

  const url = `${baseApiUrl}/objetos-alerta` +
    `?alerta_objetos=report${report}` +
    `&fim=${this.camposDatas.dataFinal}&inicio=${this.camposDatas.dataInicial}` +
    `&coluna=${this.tabela.coluna}&direcao=${this.tabela.ordenacao}` +
    `&cod_empresa=${codigoEmpresa}` +
    `&por_pagina=1`;

  await axios
    .get(url, { crossDomain: true, })
    .then((res) => {
      total = (res.data.dados.total);
    })
    .catch(() => {
      total = 0
    });

  return total;
}