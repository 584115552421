import axios from 'axios';
import { baseApiUrl } from '@/global';

export async function carregarDadosTransportadoras(report, codigoEmpresa) {
  this.transportadorasTotaisCarregando = true;

  let url = `${baseApiUrl}/objetos-alerta` +
    `?alerta_objetos=report${report}` +
    `&coluna=${this.tabela.coluna}&direcao=${this.tabela.ordenacao}` +
    `&cod_empresa=${codigoEmpresa}` +
    `&por_pagina=10000`;

  url += `&fim=${this.camposDatas.dataFinal}&inicio=${this.camposDatas.dataInicial}`;

  if(this.$route.params.estado != null){
    if(this.$route.params.estado != '')
      url += `&uf=${this.$route.params.estado}`;
  }
  let items;

  await axios.get(url).then(async (res) => {
    items = res.data.dados.data;
  });

  // Define a quantidade de pacotes por transportadora.
  let qtdPorTrans = new Map();
  items.forEach((item) => {
    if (qtdPorTrans.has(item.cod_transportadora)) {
      qtdPorTrans.set(item.cod_transportadora, qtdPorTrans.get(item.cod_transportadora) + 1);
    } else {
      qtdPorTrans.set(item.cod_transportadora, 1);
    }
  });

  this.transportadorasTotais = qtdPorTrans;
  this.transportadorasTotaisCarregando = false;
  this.navegacao.transportadoraOpcao.total = items.length;
};