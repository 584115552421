import moment from 'moment';

/** Tabela de  dados. */
export let tabela = {
  /**
   * @type {number}
   * Opção que será acessada na API pela tabela.
   * Deve ser definida de acordo com a opção escolhida.
   */
  report: 1,

  pesquisa: '',

  /**
   * @type {Array<Map>}
   * Cabeçalho  da tabela. (Vuetify Data Table)
   */
  cabecalho: [
    { text: "Código", value: "codigo", sortable: false },
    { text: "Transp.", value: "transportadora", sortable: false },
    { text: "Rastreio", value: "numero_rastreio", sortable: false },
    { text: "Prazo", value: "prazo_entrega"},
    {
      text: "Data considerada", value: "data_considerada",
      formatter: (x) => (x ? moment(x).format("DD/MM/YYYY") : null),
    },
    {
      text: "Data prevista", value: "data_prevista",
      formatter: (x) => (x ? moment(x).format("DD/MM/yyyy") : null),
    },
    {
      text: "Data entrega", value: "data_entrega",
      formatter: (x) => (x ? moment(x).format("DD/MM/yyyy") : null),
    },
    {
      text: "Data movimentação", value: "data_ultima_posicao",
      formatter: (x) => x ? moment(x).format("DD/MM/yyyy HH:mm:ss") : null,
    },
    { text: "Movimentação", value: "ultima_posicao", sortable: false },
    { text: "Reclamado", value: "reclamacao", sortable: false },
    { text: "Ações", value: "actions", sortable: false },
  ],

  items: [],
  carregando: false,
  paginaAtual: 1,
  qtdPorPagina: 50,
  totalRegistros: 0,
  qtdPaginacao: [10, 25, 50, 100],
  coluna: 'objetos.data_venda',
  ordenacao: 'asc',
  itemSelecionado: [],
};

