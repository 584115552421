<script>

import Filtro, { filtros, filtrosDatas } from '../Filtro.vue';
import Detalhes from '../../../components/objetos/frmdetalhes.vue';

import moment from 'moment';

import { fecharPagina } from '../../../functions/fechar_pagina';
import { opcoes } from './opcoes';
import { tabela } from './tabela';
import { obterQuantidades } from './dados/obter_quantidades';
import { carregarDadosDaTabela } from './dados/carregar_dados_da_tabela';
import { carregarTransportadoras } from './dados/carregar_transportadoras';
import { carregarDadosTransportadoras } from './dados/carregar_dados_transportadoras';
import router from "@/router";
import { baseApiUrl } from "@/global";
import axios from "axios";

/** @type {Map<string, any>[]} */
let transportadoras = [];

/** @type {Map<number, number>} */
let transportadorasTotais = new Map();
let transportadorasTotaisCarregando = true;

let filtroCodigoTransportadora = '';

/** @enum {string} */
class PaginasEnum {
  inicial = 'inicial';
  transportadoras = 'transportadoras';
  tabela = 'tabela';
};

/** @type {PaginasEnum} */
const Paginas = new PaginasEnum();

/** Controle de navegação pelas telas. */
const navegacao = {
  /** @type {string} */
  paginaAtual: Paginas.inicial,

  /** @type {Opcao} */
  transportadoraOpcao: '',

  telaInicio() {
    this.paginaAtual = Paginas.inicial;
  },

  /**
   * @param {number} report
   */
  telaTabela(report) {
    tabela.report = report;
    this.paginaAtual = Paginas.tabela;
    filtros.codigo = '';
    filtros.transportadoras = '';
    filtroCodigoTransportadora = '';
    filtros.rastreio = '';
    filtros.cepOrigem = '';
    filtros.cepDestino = '';

    filtrosDatas.postagem.inicial = '';
    filtrosDatas.postagem.final = '';
    filtrosDatas.postagem.exibir = false;
    filtrosDatas.considerada.inicial = '';
    filtrosDatas.considerada.final = '';
    filtrosDatas.considerada.exibir = false;
    filtrosDatas.prevista.inicial = '';
    filtrosDatas.prevista.final = '';
    filtrosDatas.prevista.exibir = false;
    filtrosDatas.entrega.inicial = '';
    filtrosDatas.entrega.final = '';
    filtrosDatas.entrega.exibir = false;
    filtrosDatas.ultimaPosicao.inicial = '';
    filtrosDatas.ultimaPosicao.final = '';
    filtrosDatas.ultimaPosicao.exibir = false;
  },

  telaTransportadoras(opcao) {
    this.transportadoraOpcao = opcao
    tabela.report = opcao.valor;
    this.paginaAtual = Paginas.transportadoras;
  }
};

/**
 * @type {?number}
 * Código da empresa para requisição na API.
 * O seu valor será atribuído quando o componente for montado.
 */
let codigoEmpresa = null;

/** Campos de data final e inicial para exibição dos dados. */
const camposDatas = {
  /** @type {boolean} Controla a exibição do diálogo de data inicial. */
  exibirData: false,

  /** @type {string} Data inicial. */
  dataInicial: moment(
    new Date(
      new Date().getTime()
    ).setDate(
      new Date().getDate() - 30
    )
  ).format('YYYY-MM-DD'),

  /** @type {string} Data final. */
  dataFinal: moment(new Date()).format('YYYY-MM-DD'),

  /**
   * Formata uma data fornecida para o padrão brasileiro.
   * @param {string} data
   * @return {string}
   */
  formatarData(data) {
    return moment(data).format('DD/MM/YYYY');
  },
};

/**
 * Altera a página atual da tabela de dados.
 * @param {string} pagina
 */
function mudarPaginaTabela(pagina) {
  tabela.paginaAtual = pagina;
  carregarDadosDaTabela.call(this, codigoEmpresa);
};

/**
 * Recarrega os dados da página caso haja alguma modificação.
 * @returns {void}
 */
function recarregarDados() {
  /** Recarrega os dados de quantidade de alertas das opções. */
  const recarregarOpcoes = () => {
    this.opcoes.map((opcao) => { opcao.carregando = true });
    this.opcoes.map(async (opcao, index) => {
      opcao.total = await obterQuantidades.call(this, index + 1, codigoEmpresa);
      opcao.carregando = false;
    });
  }

  if (this.navegacao.paginaAtual == Paginas.tabela) {
    carregarDadosDaTabela.call(this, codigoEmpresa);
    recarregarOpcoes();
    return;
  }

  if (this.navegacao.paginaAtual == Paginas.inicial) {
    recarregarOpcoes();
    return;
  }
}

/** Salva os filtros atuais. */
function salvarFiltro() {
  this.exibirFiltro = false;
  carregarDadosDaTabela.call(this, codigoEmpresa);
}

/** Limpa os filtros */
function limparFiltro(){
  this.tabela.paginaAtual = 1;

  filtros.codigo = '';
  filtros.transportadoras = '';
  filtros.rastreio = '';
  filtros.cepOrigem = '';
  filtros.cepDestino = '';

  filtrosDatas.postagem.inicial = '';
  filtrosDatas.postagem.final = '';
  filtrosDatas.postagem.exibir = false;
  filtrosDatas.considerada.inicial = '';
  filtrosDatas.considerada.final = '';
  filtrosDatas.considerada.exibir = false;
  filtrosDatas.prevista.inicial = '';
  filtrosDatas.prevista.final = '';
  filtrosDatas.prevista.exibir = false;
  filtrosDatas.entrega.inicial = '';
  filtrosDatas.entrega.final = '';
  filtrosDatas.entrega.exibir = false;
  filtrosDatas.ultimaPosicao.inicial = '';
  filtrosDatas.ultimaPosicao.final = '';
  filtrosDatas.ultimaPosicao.exibir = false;

  carregarDadosDaTabela.call(this, codigoEmpresa);
}

/** Cancela os filtros atuais. */
function cancelarFiltro() {
  this.exibirFiltro = false;
}

/** @type {string} Título da opção exibida. */
let tituloAlerta;

/**
 * Define o título de alerta.
 * Usado apenas quando a página de algum alerta é aberta.
 */
function definirTituloAlerta() {
  this.tituloAlerta = (opcoes.filter((item) => {
    if (item.valor == this.tabela.report) return item;
  }))[0].titulo;

  carregarDadosDaTabela.call(this, codigoEmpresa);
}

/** @type {boolean} Controla se o filtro será exibido. */
let exibirFiltro = false;

/** @type {boolean} Controla se o detalhe de um pedido é exibido. */
let exibirDetalhes = false;

let detalhesObjeto = {
  item: {},
  habilita: false,
  detalhes: false,
  aba_rastreio: true,
}

function carregarDetalhes(item) {
  this.detalhesObjeto.item = item;
  this.detalhesObjeto.item.cod_empresa = codigoEmpresa;
  this.detalhesObjeto.habilita = Number(item.protocolo_retorno) > 0;
  this.detalhesObjeto.detalhes = true;
  this.exibirDetalhes = true;
};

function fecharDetalhes() {
  this.exibirDetalhes = false;
  this.detalhesObjeto = {
    item: {},
    detalhes: false,
    habilita: false,
    aba_rastreio: true,
  };
};

export default {
  name: "RelatorioAlerta",

  components: {
    Filtro,
    Detalhes,
  },

  data: () => ({
    tituloAlerta,
    navegacao,
    Paginas,
    codigoEmpresa,
    opcoes,
    tabela,
    camposDatas,
    filtros,
    filtrosDatas,
    exibirFiltro,
    exibirDetalhes,
    detalhesObjeto,
    transportadorasTotais,
    transportadoras,
    transportadorasTotaisCarregando,
    filtroCodigoTransportadora,

    sortBy: [],
    sortDesc: [],
  }),

  methods: {
    fecharPagina,
    mudarPaginaTabela,
    recarregarDados,
    limparFiltro,
    salvarFiltro,
    cancelarFiltro,
    carregarDetalhes,
    fecharDetalhes,

    sortTable(){
      if(this.sortBy[0] != null){
        this.tabela.coluna = `objetos.${this.sortBy[0]}`;
        if(this.sortDesc == false || this.sortDesc[0] == false)
          this.tabela.ordenacao = 'asc';
        else
          this.tabela.ordenacao = 'desc';
      }else{
        this.tabela.coluna = 'objetos.data_venda';
        this.tabela.ordenacao = 'asc';
      }

      carregarDadosDaTabela.call(this, codigoEmpresa);
    },

    routeLoaded(){
      let report = this.$route.params.report;
      let data_inicio = this.$route.params.data_inicio;
      let data_fim = this.$route.params.data_fim;
      if(data_inicio == '' && data_fim == ''){
        data_inicio = moment(new Date(new Date().getTime()).setDate(new Date().getDate() - 30)).format('YYYY-MM-DD');
        data_fim = moment(new Date()).format('YYYY-MM-DD');
      }
      this.camposDatas.dataInicial = data_inicio;
      this.camposDatas.dataFinal = data_fim;
      if(report != null){
        for(var opcao in opcoes){
          if(opcoes[opcao].valor == report)
            var data = opcoes[opcao];
        }
        this.navegacao.telaTransportadoras(data);
      }
    },

    async objetoEncerrar(objeto){
      let url = `${baseApiUrl}/objetos-encerrar?objeto=${objeto.codigo}`;
      let data = moment(new Date()).format("YYYY-MM-DD");

      this.$swal({
        title: 'Confirmação',
        text: `Tem certeza que deseja finalizar o objeto ${objeto.codigo}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        confirmButtonColor: '#4BB543',
        cancelButtonColor: '#d33',
        allowOutsideClick: false,
      }).then(async(res) => {
        if(res.isConfirmed) {
          await axios
            .patch(url, {
              "status": "F",
              "data_encerramento": data,
            })
              .then(() => {
                for(let i = 0; i < this.grid.objetos.items.length; i++){
                  if(this.grid.objetos.items[i].codigo == objeto.codigo){
                    this.grid.objetos.items.splice(i, 1);              
                  }
                }
              }).catch(() => {
                this.$swal({
                  title: 'Que pena!!!',
                  text: `Não foi possível finalizar o objeto ${objeto.codigo}.`,
                  icon: 'warning',
                  confirmButtonText: 'Ok',
                  confirmButtonColor: '#4BB543',
                  allowOutsideClick: true,
                });
              })
        }
      });
    },

    voltarPagina(){
      if(this.$route.params.report != null && this.navegacao.paginaAtual == Paginas.transportadoras)
        router.push('/');
      if(this.$route.params.report != null && this.navegacao.paginaAtual == Paginas.tabela)
        this.navegacao.paginaAtual = Paginas.transportadoras;
      else{
        if(navegacao.paginaAtual != Paginas.inicial)
          this.navegacao.telaInicio();
        else
          router.push('/');
      }
    },
  },

  async created() {
    codigoEmpresa = Number(this.$store.state.cod_empresa);
    carregarTransportadoras.call(this);
    this.navegacao.paginaAtual = Paginas.inicial;
    if(this.$route.params.data_inicio != null && this.$route.params.report != null)
      this.routeLoaded();
    else {
      await recarregarDados.call(this);
    }
  },

  computed: {
    computedHeaders(){
      if(this.tabela.report != 2){
        return this.tabela.cabecalho.filter(item => item.text!='Ações');
      }else{
        return this.tabela.cabecalho;
      }      
    }
  },

  watch: {
    $route() {
      this.$nextTick(this.routeLoaded);
    },

    'tabela.report': function (val, oldVal){
      if(val != oldVal){
        this.tabela.paginaAtual = 1;
        this.sortBy = [];
        this.sortDesc = [];
      }
    },

    'navegacao.paginaAtual': async function (newVar) {
      if (newVar == Paginas.tabela) {
        definirTituloAlerta.call(this);
      }

      if (newVar == Paginas.transportadoras) {
        definirTituloAlerta.call(this);
        await carregarDadosTransportadoras.call(this, this.navegacao.transportadoraOpcao.valor, codigoEmpresa);
      }

      if (newVar == Paginas.inicial) {
        this.tabela.items = [];

        this.filtroCodigoTransportadora = '';
      }
    },
  },
};

</script>

<template>
  <article>
    <Detalhes v-if="exibirDetalhes" v-on:detclose="() => fecharDetalhes()" :consulta="detalhesObjeto" />

    <Filtro :exibir="exibirFiltro" :cancelarFiltro="() => cancelarFiltro()" :salvarFiltro="() => salvarFiltro()"
      :propFiltros="filtros" :propFiltrosDatas="filtrosDatas" titulo="Objetos em Alerta" />

    <v-toolbar flat>
      <v-icon size="100">
        fas fa-exclamation-circle
      </v-icon>
      <v-toolbar-title class="pl-4" style="font-size: 24px">
        Objetos em Alerta
        {{
          navegacao.paginaAtual == Paginas.transportadoras ||
          navegacao.paginaAtual == Paginas.tabela ?
          ' - ' + tituloAlerta : null
        }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon @click="voltarPagina">
        <v-icon>fas fa-times</v-icon>
      </v-app-bar-nav-icon>
    </v-toolbar>

    <!-- Data inicial e data final. -->
    <v-card outlined>
      <v-card-text style="padding: 5px; padding-left: 20px; padding-right: 20px; margin: 0px;">
        <v-row dense align="start" justify="start" style="margin-top: 10px;">
          <v-col cols="12" sm="4" md="3" style="height: 60px;">
            <v-text-field color="#018656" dense outlined label="Data inicial" prepend-icon="fas fa-calendar-alt"
              :value="camposDatas.formatarData(camposDatas.dataInicial)" @click="() => camposDatas.exibirData = true">
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="4" md="3" style="height: 60px;">
            <v-text-field color="#018656" dense outlined label="Data final"
              :value="camposDatas.formatarData(camposDatas.dataFinal)" @click="() => camposDatas.exibirData = true">
            </v-text-field>
          </v-col>

          <v-dialog v-model="camposDatas.exibirData" width="fit-content">
            <v-card outlined style="max-width: 800px; height: 100%; max-height: 540px;">
              <v-card-text class="mt-6">
                <v-card>
                  <v-card-text style="padding-top: 10px;">
                    <v-row dense>
                      <v-col>
                        <h1
                          style="font-size: 1rem; text-align: center; margin-bottom: 5px; color: black; font-weight: normal;">
                          Data inicial
                        </h1>
                        <v-date-picker color="#018656" v-model="camposDatas.dataInicial" size="100" width="100%">
                        </v-date-picker>
                      </v-col>

                      <v-col>
                        <h1
                          style="font-size: 1rem; text-align: center; margin-bottom: 5px; color: black; font-weight: normal;">
                          Data final
                        </h1>
                        <v-date-picker color="#018656" v-model="camposDatas.dataFinal" size="100" width="100%">
                        </v-date-picker>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>

                <v-row style="margin-top: 10px;" dense justify="end">
                  <v-col cols="12" sm="12">
                    <v-btn color="#018656" style="color: #FFFFFF; width:100%; margin-bottom: 10px;"
                      @click="camposDatas.exibirData = false, recarregarDados()">
                      CONFIRMAR
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-dialog>

          <v-spacer></v-spacer>

          <v-col v-if="navegacao.paginaAtual == Paginas.tabela" cols="12" sm="3" md="2" style="height: 60px;">
            <v-btn type="button" color="#FF5252" style="color: #FFFFFF; width: 100%; height: 40px;"
              @click="() => limparFiltro()">
              <v-icon dense class="mr-2">fas fa-broom</v-icon>
              LIMPAR
            </v-btn>
          </v-col>

          <v-col v-if="navegacao.paginaAtual == Paginas.tabela" cols="12" sm="3" md="2" style="height: 60px;">
            <v-btn type="button" color="#018656" style="color: #FFFFFF; width: 100%; height: 40px;"
              @click="() => exibirFiltro = true">
              <v-icon dense class="mx-2">fas fa-filter</v-icon>
              FILTRAR
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- Todas as opções. -->
    <v-card outlined style="margin-top: 10px;" v-if="navegacao.paginaAtual == Paginas.inicial">
      <v-card-text style="min-height: calc(100vh - 265px); display: flex; align-items: center; justify-content: center;">
        <v-row dense>
          <v-col cols="12" sm="6" md="3" lg="2" v-for="opcao in opcoes" :key="opcao.titulo">
            <v-card>
              <v-card-text align="center" class="px-4 py-4">
                <div style="display: flex; align-items: center; width:100%;  justify-content: center;">
                  <div style="display: flex; flex-direction: column;">
                    <p style="font-size: 1rem; text-align: start;" class="mt-2">
                      {{ opcao.titulo }}
                    </p>

                    <h1 style="text-align: start; font-size: 2rem;">
                      <template v-if="opcao.carregando">
                        <v-progress-circular indeterminate color="#018656"></v-progress-circular>
                      </template>
                      <template v-else>
                        {{ opcao.total }}
                      </template>
                    </h1>
                  </div>

                  <v-spacer></v-spacer>

                  <img style="height: 100px; width: 80px;" :src="opcao.imagem" :alt="opcao.titulo">
                </div>

                <v-btn @click="() => navegacao.telaTransportadoras(opcao)" type="button" color="#018656"
                  style="color: #FFFFFF; font-size: 0.6rem; width: 100%;" class="mt-4">
                  <v-icon dense size="18px" class="mr-2">fas fa-truck</v-icon>
                  POR TRANSPORTADORAS
                </v-btn>

                <v-btn @click="() => navegacao.telaTabela(opcao.valor)" type="button" color="#757575"
                  style="color: #FFFFFF; font-size: 0.6rem; width: 100%;" class="mt-2">
                  <v-icon dense size="18px" class="mr-2">fas fa-globe</v-icon>
                  TODAS AS OCORRÊNCIAS
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- Transportadoras. -->
    <v-card outlined style="height: calc(100vh - 240px); margin-top: 10px;"
      v-if="navegacao.paginaAtual == Paginas.transportadoras" class="overflow-y-auto">
      <v-card-text style="height: 100%;">
        <v-row style="height: 100%" align="center" justify="center" dense v-if="transportadorasTotaisCarregando">
          <v-progress-circular indeterminate color="#018656"></v-progress-circular>
        </v-row>
        <v-row dense v-else>
          <v-col cols="12" sm="6" md="4" style="height: 205px;">
            <v-card style="height: 100%;">
              <v-card-text align="center">
                <h1 style="height: 80px; font-size: 2rem; display: grid; place-items: center;">TODAS</h1>

                <v-spacer></v-spacer>

                <p class="mt-2" style="text-align: start; font-size: 1.5rem;">Total: <strong>{{
                  navegacao.transportadoraOpcao.total
                }}</strong></p>

                <v-spacer></v-spacer>

                <v-btn @click="() => { filtroCodigoTransportadora = ''; navegacao.telaTabela(navegacao.transportadoraOpcao.valor) }" type="button"
                  color="#018656" style="color: #FFFFFF; width: 100%;" class="mt-1">
                  <v-icon dense class="mr-2">fas fa-globe</v-icon>
                  VER TODAS AS OCORRÊNCIAS
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col v-for="item in transportadorasTotais" :key="item[0]" cols="12" sm="6" md="4" style="height: 205px;">
            <v-card style="height: 100%;">
              <v-card-text align="center">
                <h1 style="height: 80px; font-size: 2rem; display: grid; place-items: center;">
                  {{
                    transportadoras.filter((value) => {
                      if (value['codigo'] == item[0]) return value
                    })[0]['transportadora']
                  }}
                </h1>

                <v-spacer></v-spacer>

                <p class="mt-2" style="text-align: start; font-size: 1.5rem;">Ocorrências:
                  <strong>{{ item[1] }}</strong>
                </p>

                <v-spacer></v-spacer>

                <v-btn @click="() => { filtroCodigoTransportadora = item[0]; navegacao.telaTabela(navegacao.transportadoraOpcao.valor) }"
                  type="button" class="mt-1" color="#018656" style="color: #FFFFFF; width: 100%;">
                  <v-icon dense class="mx-2">fas fa-chevron-circle-down</v-icon>
                  VISUALIZAR
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- Tabela com os dados. -->
    <v-card outlined style="margin-top: 10px;" v-if="navegacao.paginaAtual == Paginas.tabela">
      <v-card-title>
        <v-text-field class="mx-4" color="#018656" v-model="tabela.pesquisa" prepend-icon="fas fa-search"
          label="Pesquisa rápida" single-line hide-details>
        </v-text-field>
      </v-card-title>
      <v-card-text>
        <v-row style="height: calc(100vh - 385px);" class="overflow-y-auto">
          <v-col>
            <v-data-table color="#018656" style="color: black;" dense :headers="computedHeaders" :items="tabela.items"
              :items-per-page="tabela.qtdPorPagina" fixed-header hide-default-footer class="elevation-1 pt-4"
              :search="tabela.pesquisa" :loading="tabela.carregando" item-key="codigo" v-model="tabela.itemSelecionado"
              :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" @update:sort-by="sortTable" @update:sort-desc="sortTable">

              <template v-for="header in tabela.cabecalho.filter((header) => header.hasOwnProperty('formatter'))"
                v-slot:[`item.${header.value}`]="{ header, value }">
                {{ header.formatter(value) }}
              </template>
              
              <template #item.reclamacao="{ item }">
                {{ item.reclamacao == 1 ? "Sim" : "" }}
              </template>
              <template #item.codigo="{ item }">
                <button type="button" style="font-weight: bold; color: #018656;" @click="() => carregarDetalhes(item)">
                  {{ item.numero_pedido }}
                </button>
              </template>

              <template #item.actions="{ item }">
                <v-btn color="#ef233c" style="color: white;" dense small @click="objetoEncerrar(item)">
                  Finalizar
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <!-- Contador de páginas. -->
        <v-row style="margin-top: 15px; position: relative;">
          <v-col cols="3" style="max-height: 65px; position: absolute; left: 0; right: 0; margin: auto;">
            <v-pagination v-model="tabela.paginaAtual" style="color: #018656" color="#018656"
              :length="Math.ceil(tabela.totalRegistros / tabela.qtdPorPagina)" @input="mudarPaginaTabela"
              :total-visible="5">
            </v-pagination>
          </v-col>

          <v-spacer></v-spacer>

          <v-col class="d-none d-md-flex" cols="2" style="max-height: 65px;">
            <v-select color="#018656" dense outlined label="Resultados por página" :items="tabela.qtdPaginacao"
              item-text="text" item-value="value" v-model="tabela.qtdPorPagina">
            </v-select>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </article>
</template>
