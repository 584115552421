import axios from 'axios';
import { baseApiUrl } from '@/global';

/** @return  {Promise<Map<string, any>[]>} */
export async function carregarTransportadoras() {
  const url = `${baseApiUrl}/transportadoras`;

  /** @type {Map<string, any>[]} */
  let trans = [];

  await axios.get(url).then((res) => {
    trans = res.data.transportadoras;
  });

  this.transportadoras = trans;
}
