import router from "@/router";

/**
 * Fecha a página atual, levando para a rota inicial.
 * 
 * Deve receber o $router como parâmetro.
 * 
 * ```vue
 * <script>
 *  import fecharPagina from '../functions/fecharPagina';
 * 
 *  export default {
 *    methods: {
 *      fecharPagina,
 *    }
 *  }
 * </script>
 * 
 * <template>
 *  <button \@click="fecharPagina" type="button">Fechar</button>
 * </template>
 * ```
 */
export function fecharPagina() {
  router.push("/");
};